import React, { Component } from 'react'

export default class NotificationPopup extends Component {
  constructor(props){
    super(props)
  }



  render() {
    return (
      <div style={{zIndex:'11111',position:'fixed', top:0, width: '100%', background: this.props.status === 'error' ? '#f8d7da':'#d4edda', textAlign:'center', color:'#fff' }}>
        <p style={{margin:0}}>{this.props.children}</p>
    </div>
    )
  }
}
